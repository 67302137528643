<template>
  <lazy-hydrate
    :trigger-hydration="hydrate"
    never
  >
    <div
      :class="['single-step-calculator', {
        'single-step-calculator--local': LOCAL,
        'single-step-calculator--on-beige-background': onBeigeBackground
      }]"
    >
      <zg-transition-fade @after-transition="scrollToStep2">
        <single-step-application
          v-if="showForm"
          :on-beige-background="onBeigeBackground"
        />

        <lazy-hydrate
          v-else
          when-idle
        >
          <div
            :class="['calculator', {
              'calculator--local': LOCAL
            }]"
          >
            <language-translation
              v-if="showTranslation"
              key="language-translation"
            />

            <calculator
              key="calculator"
            />

            <buttons
              key="buttons"
              :submit-label="content.next"
              @submit="submit"
            />

            <usps
              key="usps"
              :usps="content.usps"
            />

            <disclaimer key="disclaimer" />

            <!-- If query trust_stars is in url trustpilot shows in form. -->
            <trustpilot />

            <sticky-cta
              :label="content.next"
              target=".calculator"
            />
          </div>
        </lazy-hydrate>
      </zg-transition-fade>

      <!-- used to test sticky cta -->
      <div
        v-if="LOCAL"
        style="padding-bottom: 1500px;"
      />
    </div>
  </lazy-hydrate>
</template>

<script>
  import { arraysAreEqual, getUrlSearchParams, hotjarTagRecording } from '@ocp-zmarta/zmarta-cl'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions, mapGetters } from 'vuex'
  import Buttons from '../../application/cl/single-step/_shared/Buttons'
  import Disclaimer from '../../application/cl/single-step/_shared/Disclaimer'
  import StickyCta from '../../application/cl/single-step/_shared/StickyCta'
  import Trustpilot from '../../elements/_shared/trustpilot/Trustpilot'
  import LanguageTranslation from '../../elements/cl/misc/LanguageTranslation'

  export default {
    name: 'SingleStepCalculator',
    components: {
      StickyCta,
      LazyHydrate,
      Disclaimer,
      Buttons,
      LanguageTranslation,
      Trustpilot,
      Calculator: () => import(/* webpackChunkName: 'application/cl/single-step/shared/calculator' */
        '../../application/cl/single-step/_shared/Calculator'),
      Usps: () => import(/* webpackChunkName: 'elements/shared/misc/usps' */
        '../../elements/_shared/misc/Usps.vue'),
      SingleStepApplication: () => import(/* webpackChunkName: 'application/cl/single-step' */
        '../../application/cl/single-step/SingleStep'),
      ZgTransitionFade: () => import(/* webpackChunkName: 'zc/zg-transition-fade' */
        '@zc/components/ZgTransitionFade/ZgTransitionFade')
    },
    data: () => ({
      hydrate: false,
      loadComponents: {
        needed: [
          'mounted'
        ],
        loaded: [],
        finished: false
      }
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('router', ['getRoute', 'getQuery']),
      ...mapGetters('misc', ['getMisc', 'getIsMobile']),
      ...mapGetters('features', ['getFeatures']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('cl/application', ['getShowForm']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      content () {
        return this.getContent?.application?.singleStep ?? {}
      },
      onBeigeBackground () {
        return this.getQuery?.['on-background'] === 'beige'
      },
      showForm () {
        return this.getShowForm
      },
      showTranslation () {
        return this.getFeatures?.translations?.enabled
      },
      isEnglishLocale () {
        return this.getLocale === 'en'
      }
    },
    async serverPrefetch () {
      await this.init()
    },
    async mounted () {
      await this.init()
      hotjarTagRecording('cl/form/single-step/calculator')

      await Promise.all([
        this.setField({ group: 'global', field: 'brandId', value: this.getRoute?.meta?.brandId }),
        this.setField({ group: 'global', field: 'brokerId', value: this.getRoute?.meta?.brokerId }),
        this.setField({ group: 'global', field: 'mediaBrokerId', value: this.getRoute?.meta?.mediaBrokerId })
      ])

      if (this.getFormAlternatives?.loaded !== null) {
        this.fetchFormAlternatives().catch()
      }

      this.hydrate = true
      await this.loaded('mounted')
    },
    methods: {
      ...mapActions('form', ['setField']),
      ...mapActions('misc', ['scrollToElement']),
      ...mapActions('cl/formAlternatives', ['fetchFormAlternatives']),
      ...mapActions('cl/application', ['initApplication', 'setShowForm']),
      async init () {
        await this.initApplication({ type: 'singleStep', initialStep: 2 })
      },
      async loaded (component) {
        if (this.loadComponents.finished) return
        this.loadComponents.loaded.push(component)

        if (!arraysAreEqual(
          this.loadComponents.needed,
          this.loadComponents.loaded
        )) return

        this.loadComponents.finished = true
        this.$emit('loaded')
      },
      async submit () {
        const loanAmount = parseInt(this.getForm?.global?.loanAmount?.value)
        const repaymentYears = parseInt(this.getForm?.global?.repaymentYears?.value)
        const params = getUrlSearchParams()
        const testcafe = params?.testcafe

        if (testcafe) {
          this.setShowForm(true)
        } else if (this.getExperiments?.['cl-single-vs-multi-step']?.variationKey === 'multiple_steps' && this.getExperiments?.['cl-single-vs-multi-step']?.activated) {
          if (this.isEnglishLocale) {
            window.location.href = `/en/loans/personal/apply-2?repaymentYears=${repaymentYears}&loanAmount=${loanAmount}`
          } else {
            window.location.href = `/lana-pengar/privatlan/ansok-2?repaymentYears=${repaymentYears}&loanAmount=${loanAmount}`
          }
        } else {
          this.setShowForm(true)
        }
      },
      scrollToStep2 () {
        if (!this.showForm && !this.getIsMobile) return
        this.scrollToElement({ element: '#personalDetails' })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .single-step-calculator {
    width: 100%;
    min-height: auto;

    &--local {
      min-height: 100vh;
      background-color: color(beige);

      @include mq(medium) {
        padding: rhythm(large) 0;
      }
    }
  }

  .calculator {
    position: relative;

    &--local {
      padding: rhythm(small);
      border-radius: radius(box);
      background-color: color(white);
      box-shadow: box-shadow();

      @include mq(medium) {
        width: rem(560px) + (rhythm(medium) * 2);
        margin: 0 auto;
        padding: rhythm(medium);
      }
    }
  }
</style>
